import React from 'react';
import PropTypes from 'prop-types';

import { injectClient } from 'shared/src/components/contexts/ClientContext';
import { useI18Next } from 'shared/src/components/contexts/I18NextContext';

import Request from '../../../data/Request';
import WaitFor from '../../../data/WaitFor';
import Icon from '../../elements/Icon';
import imageParams from '../../../utils/imageParams';

const IMG_SIZE = 160;

const imgStyles = {
  height: `${IMG_SIZE}px`,
  width: `${IMG_SIZE}px`,
  display: 'block'
};

const imgButtonStyles = {
  height: `${IMG_SIZE}px`,
  width: `${IMG_SIZE}px`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};

const LibraryImageItem = ({
  imageId, imageUrl, client, onSelect
}) => {
  const fetchPreview = ({ imageId, imageUrl }) => { // eslint-disable-line no-shadow
    if (imageUrl) return { url: imageUrl };
    if (!imageId) return null;
    return client.images.for(imageId).details();
  };

  return (
    <div onClick={() => onSelect(imageId)}>
      <Request request={fetchPreview} params={{ imageId, imageUrl }} cache="image">
        {({ data, loaded }) => (
          <WaitFor waitFor={loaded} wrapContents>
            <img
              src={data && data.url && imageParams(data.url, { w: IMG_SIZE, h: IMG_SIZE, fit: 'crop' })}
              style={imgStyles}
              alt=""
            />
          </WaitFor>
        )}
      </Request>
    </div>
  );
};

LibraryImageItem.propTypes = {
  imageId: PropTypes.string,
  imageUrl: PropTypes.string,
  client: PropTypes.object,
  onSelect: PropTypes.func
};

const LibraryImage = injectClient(LibraryImageItem);

const LibraryImageButton = ({ onClick }) => {
  const { strings } = useI18Next();
  return (
    <div onClick={onClick} style={imgButtonStyles}>
      <Icon name="add" /> {strings('ui.component.libraryImage.upload')}...
    </div>
  );
};

LibraryImageButton.propTypes = {
  onClick: PropTypes.func
};

export {
  LibraryImage,
  LibraryImageButton
};

