import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Flex,
  Link,
  Text,
} from "@chakra-ui/react";
import React from "react";
import _ from "lodash";
import { Link as RouterLink } from "react-router-dom";
import Profile from "shared/src/interfaces/Profile.interface";

const buildLink = (
  post: { healthy: boolean; lastPostId: string; error?: string },
  context: "dashboard" | "admin",
  businessId?: string
): string => {
  if (context === "dashboard") {
    return `./social/${post.lastPostId}`;
  }
  if (context === "admin" && businessId) {
    return `/social/posts?businessId=${businessId}&postId=${post.lastPostId}`;
  }
  return "";
};

const ProfileHealth = ({
  profile,
  hasLinks,
  context,
  businessId,
  ...cssProps
}: {
  profile: Profile;
  context: "dashboard" | "admin";
  hasLinks?: boolean;
  businessId?: string;
  [key: string]: any 
}) => {
  if (!profile?.health) return null;
  const unhealthyEntries = Object.entries(profile.health).filter(
    ([types, post]) => !post.healthy
  );
  if (!unhealthyEntries.length) return null;
  const fallbackError = "An unexpected error in your post has occurred";
  return (
    <Alert status="error" fontSize="lg" {...cssProps}>
      <Flex align="center">
        <AlertIcon />
        <Flex direction="column" gap="0.5em">
          <AlertTitle>
            This account is experiencing the following errors
          </AlertTitle>
          {unhealthyEntries.map(([type, post]) => (
            <AlertDescription key={type}>
              <Text fontSize="lg" fontWeight="bold">
                {_.startCase(type)}
                {hasLinks ? (
                  <Link
                    as={RouterLink}
                    to={buildLink(post, context, businessId)}
                    sx={{
                      textDecoration: "underline",
                      fontWeight: "normal",
                      paddingLeft: "0.75rem",
                    }}
                  >
                    {_.capitalize(post.error || fallbackError)}
                  </Link>
                ) : (
                  <span
                    style={{ fontWeight: "normal", paddingLeft: "0.75rem" }}
                  >
                    {_.capitalize(post.error || fallbackError)}
                  </span>
                )}
              </Text>
            </AlertDescription>
          ))}
        </Flex>
      </Flex>
    </Alert>
  );
};

export default ProfileHealth;
