import {
  Box,
  Center,
  Spinner,
  StackDivider,
  Text,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import useRequest from 'shared/src/hooks/useRequest';
import ErrorAlert from '../../chakra/ErrorAlert';
import InlineSupportLink from '../../support/InlineSupportLink';

const API_KEY = process.env.REACT_APP_GOOGLE_PLACES_API_KEY;

type Place = {
  id: string,
  displayName: {
    text: string
  },
  formattedAddress: string
}

const searchPlaces = async ({ query }: { query: string }) => {
  if (!query) return null;

  // NOTE: We use the NEW Places API because the old one doesn't support CORS
  const result = await fetch(
    'https://places.googleapis.com/v1/places:searchText',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Goog-Api-Key': API_KEY,
        'x-Goog-FieldMask': [
          'places.id',
          'places.displayName',
          'places.formattedAddress',
          'places.primaryType',
          // 'places.reviews',
          'places.websiteUri',
        ].join(','),
      },
      body: JSON.stringify({
        textQuery: query,
        pageSize: 5,
      }),
    },
  );

  return result.json();
};

const searchAddress = async ({ address, name }: { address: any, name: string }) => {
  const formattedAddress = [
    address.suite,
    address.streetAddress,
    address.street,
    address.city,
    address.state,
    address.postalcode,
    address.country,
  ]
    .filter(Boolean)
    .join(' ');

  let query = `${name} near ${formattedAddress}`;
  const result = await searchPlaces({ query });

  // result.places will be undefined if no results
  if (result.places?.length) return result;

  query = `businesses near ${formattedAddress}`;

  return searchPlaces({ query });
};

const GooglePlaceSelector = ({ address, name, onSelect, value }: {
  address: object,
  name: string,
  onSelect: (place: Place) => void,
  value: string
}) => {
  return (
    <Box>
      <GooglePlaceSearch address={address} name={name} onSelect={onSelect} value={value} />
    </Box>
  );
};

const GooglePlaceSearch = ({ address, name, onSelect, value }: {
  address: object,
  name: string,
  onSelect: (place: Place) => void,
  value?: string
}) => {
  const searchRequest = useRequest(
    searchAddress,
    { address, name },
  );

  if (searchRequest.loading) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }
  if (searchRequest.error) return <ErrorAlert error={searchRequest.error} />;
  const places = searchRequest?.result?.places ?? [];
  return (
    <VStack
      divider={<StackDivider border="1px solid gray.200" sx={{ margin: '0 !important' }} />}
      align="stretch"
    >
      {
        places.map((place: Place) => (
          <PlaceRow key={place.id} place={place} onClick={onSelect} active={value === place.id} />
        ))
      }
    </VStack>
  );
};

const PlaceRow = ({ place, onClick, active }: {
  place: Place,
  // eslint-disable-next-line no-shadow
  onClick: (place: Place) => void,
  active: boolean
}) => {
  return (
    <Box
      border="2px solid transparent"
      borderLeftColor={active ? 'blue.600' : undefined}
      p="0.5em"
      _hover={{ backgroundColor: 'gray.100' }}
      cursor="pointer"
      onClick={() => onClick(place)}
    >
      <Text fontWeight="bold">{place.displayName.text}</Text>
      <Text>{place.formattedAddress}</Text>
    </Box>
  );
};

export default GooglePlaceSelector;
