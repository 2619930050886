import { strings } from '../../i18n';

/**
 * Defines Social AI ONLY plans
 */
export default [
  {
    key: 'socialai',
    name: 'Social AI',
    platformId: process.env.REACT_APP_PLATFORM_PLAN_ID_SOCIALAI,
    pricePoints: [
      {
        tier: 1,
        value: 9900,
        currencyCode: 'CAD',
        period: 1,
        periodUnit: 'month',
        chargebeePlanId: 'social-ai-local-monthly-cad',
        priceText: strings('ui.component.planConstants.socialai.priceText'),
      },
      {
        tier: 1,
        value: 9900,
        currencyCode: 'USD',
        period: 1,
        periodUnit: 'month',
        chargebeePlanId: 'social-ai-local-monthly-usd',
        priceText: strings('ui.component.planConstants.socialai.priceText'),
      },
      {
        tier: 1,
        value: 99000,
        currencyCode: 'CAD',
        period: 1,
        periodUnit: 'year',
        chargebeePlanId: 'social-ai-local-annual-cad',
        priceText: strings('ui.component.planConstants.socialai.priceTextAnnual'),
        savings: 1 - (99000 / (9900 * 12))
      },
      {
        tier: 1,
        value: 99000,
        currencyCode: 'USD',
        period: 1,
        periodUnit: 'year',
        chargebeePlanId: 'social-ai-local-annual-usd',
        priceText: strings('ui.component.planConstants.socialai.priceTextAnnual'),
        savings: 1 - (99000 / (9900 * 12))
      }
    ],
    features: [
      'Up to 20 posts & stories per month',
      'Automatic captions & hashtags',
      'Automatic scheduling & posting',
      'Facebook & Instagram included',
    ],
    subHeader: strings('ui.component.planConstants.socialai.subHeader'),
    featHeader: 'AI-created social media posts & stories, including:'
  },
  {
    key: 'socialaiplus',
    name: 'Social AI Plus',
    platformId: process.env.REACT_APP_PLATFORM_PLAN_ID_SOCIALAIPLUS,
    pricePoints: [
      {
        tier: 2,
        value: 19900,
        currencyCode: 'CAD',
        period: 1,
        periodUnit: 'month',
        chargebeePlanId: 'social-ai-plus-local-monthly-cad',
        priceText: strings('ui.component.planConstants.socialai.priceText'),
      },
      {
        tier: 2,
        value: 19900,
        currencyCode: 'USD',
        period: 1,
        periodUnit: 'month',
        chargebeePlanId: 'social-ai-plus-local-monthly-usd',
        priceText: strings('ui.component.planConstants.socialai.priceText'),
      },
      {
        tier: 2,
        value: 199000,
        currencyCode: 'CAD',
        period: 1,
        periodUnit: 'year',
        chargebeePlanId: 'social-ai-plus-local-annual-cad',
        priceText: strings('ui.component.planConstants.socialai.priceTextAnnual'),
        savings: 1 - (99000 / (9900 * 12))
      },
      {
        tier: 2,
        value: 199000,
        currencyCode: 'USD',
        period: 1,
        periodUnit: 'year',
        chargebeePlanId: 'social-ai-plus-local-annual-usd',
        priceText: strings('ui.component.planConstants.socialai.priceTextAnnual'),
        savings: 1 - (99000 / (9900 * 12))
      }
    ],
    features: [
      'Up to 30 posts & stories per month',
      'Dynamic branding and content controls',
      'Additional post & story types',
    ],
    subHeader: 'More posts, more variety, customized to your brand',
    featHeader: 'Everything in Social AI, plus:'
  },
];
