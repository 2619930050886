import PropTypes from 'prop-types';
import React from 'react';
import {
  Avatar,
  AvatarBadge,
  theme
} from '@chakra-ui/react';
import imageParams from '../../utils/imageParams';

const ImageRenderer = (props) => {
  const {
    imageUrl,
    name,
    size,
    width,
    height,
    status,
    ...rest
  } = props;

  const imgixUrl = imageParams(
    imageUrl,
    { w: width, h: height, fit: (width && height) ? 'crop' : undefined }
  );

  return (
    <Avatar src={imgixUrl} name={name} bg={theme.colors.gray[300]} size={size} {...rest}>
      {status && (
        <AvatarBadge
          boxSize="1em"
          borderColor={theme.colors.white}
          bg={status === 'live' ? theme.colors.green[500] : theme.colors.orange[500]}
        />
      )}
    </Avatar>
  );
};

ImageRenderer.propTypes = {
  imageUrl: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  status: PropTypes.oneOf(['live', 'test']),
};

ImageRenderer.defaultProps = {
  size: 'md',
};

export default ImageRenderer;
